import React from "react";

export function HeartStroked(props) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="#FF7C4E"
      stroke="#FFA0B7"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-pulse mx-auto items-center"
    >
      <path
        d="M39.075 8.64376C38.1173 7.68564 36.9803 6.92559 35.7288 6.40704C34.4773 5.88848 33.1359 5.62158 31.7812 5.62158C30.4266 5.62158 29.0852 5.88848 27.8337 6.40704C26.5822 6.92559 25.4451 7.68564 24.4875 8.64376L22.5 10.6313L20.5125 8.64376C18.5781 6.70933 15.9544 5.62258 13.2187 5.62258C10.483 5.62258 7.8594 6.70933 5.92498 8.64376C3.99056 10.5782 2.90381 13.2018 2.90381 15.9375C2.90381 18.6732 3.99056 21.2968 5.92498 23.2313L7.91248 25.2188L22.5 39.8063L37.0875 25.2188L39.075 23.2313C40.0331 22.2736 40.7931 21.1365 41.3117 19.885C41.8302 18.6336 42.0972 17.2922 42.0972 15.9375C42.0972 14.5828 41.8302 13.2414 41.3117 11.99C40.7931 10.7385 40.0331 9.60142 39.075 8.64376V8.64376Z"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
